import { OpenInNew } from "@mui/icons-material";
import { Box, Divider, Link, Stack, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { ChangeEvent, FC, useState } from "react";
import { Link as RouterLink, useNavigate, useSearchParams } from "react-router-dom";
import { AsideCardLayout } from "../../../../components/AsideCardLayout/AsideCardLayout";
import { EmptyText } from "../../../../components/EmptyText/EmptyText";
import { NotificationAlerts } from "../../../../components/NotificationAlerts/NotificationAlerts";
import { AddedButton } from "../../../../components/UI/Buttons/AddedButton/AddedButton";
import { CopyIconButton } from "../../../../components/UI/Buttons/CopyIconButton/CopyIconButton";
import { CustomIconButton } from "../../../../components/UI/Buttons/CustomIconButton/CustomIconButton";
import { EditModeButton } from "../../../../components/UI/Buttons/EditModeButton/EditModeButton";
import { RemoveItemButton } from "../../../../components/UI/Buttons/RemoveItemButton/RemoveItemButton";
import { IEditContractDataRequest } from "../../../../services/payments/IPaymentsRequest";
import { IFinanceAdditionalAgreement } from "../../../../services/payments/IPaymentsResponse";
import {
  CONTRACTOR_CARD_OPEN,
  FINANCE_CARD_OPEN,
  INTEGRATION_CARD_OPEN,
  PAYMENT_NUMBER,
} from "../../../../shared/constants/searchParams";
import { priceTransormFormat } from "../../../../shared/functions/functions";
import CreateDocumentDatePicker from "../../../ContrActorAsidePage/pages/CreateDocument/ui/CreateDocumentDatePicker/CreateDocumentDatePicker";
import CreateDocumentField from "../../../ContrActorAsidePage/pages/CreateDocument/ui/CreateDocumentField/CreateDocumentField";
import { IContractBlock } from "./IContractBlock";
import { ContractCheckbox } from "./ui/ContractCheckbox/ContractCheckbox";

export const ContractBlock: FC<IContractBlock> = (props) => {
  const {
    paymentId,
    contract,
    attachment,
    counteragentId,
    readOnly,
    editContractBlock,
    setEditContractBlock,
    additionalAgreement,
    trackNumber,
    isOriginalsReceived,
    setEditAdditionalAgreement,
    updateContractBlock,
    syncContractWithEditContract,
    closeContarctEdit,
    removeAdditionalAgreement,
  } = props;

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isShowError, setShowError] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isAddAdditionalAgreement, setAddAdditionalAgreement] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const checkParamsForIntegration = searchParams.has(INTEGRATION_CARD_OPEN);

  const startEdit = () => {
    syncContractWithEditContract();
    setEdit(true);
  };

  const closeEdit = () => {
    closeContarctEdit();
    setEdit(false);
    setAddAdditionalAgreement(false);
  };

  const handleRemoveAdditionalAgreement = () => {
    setAddAdditionalAgreement(false);
    removeAdditionalAgreement();
  };

  const getContractorDocumentPage = () => {
    if (paymentId) {
      if (searchParams.has(FINANCE_CARD_OPEN) && searchParams.has(CONTRACTOR_CARD_OPEN)) {
        searchParams.delete(FINANCE_CARD_OPEN);
      } else {
        searchParams.set(CONTRACTOR_CARD_OPEN, counteragentId.toString());
        searchParams.set(PAYMENT_NUMBER, paymentId?.toString());
      }
      setSearchParams(searchParams);
    }
  };

  const handleContractInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setEditContractBlock(name as keyof IEditContractDataRequest, value);
  };

  const handleAdditionalAgreementChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setEditAdditionalAgreement(name as keyof IFinanceAdditionalAgreement, value);
  };

  const handleDateChange = (date: Dayjs | null, name?: string) => {
    if (date && date.isValid()) {
      setEditAdditionalAgreement(name as keyof IFinanceAdditionalAgreement, date.format());
    } else {
      setEditAdditionalAgreement(name as keyof IFinanceAdditionalAgreement, "");
    }
  };

  const submit = () => {
    if (paymentId && editContractBlock) {
      setLoading(true);
      updateContractBlock(paymentId, editContractBlock)
        .then(() => setEdit(false))
        .catch((err) => {
          setShowError(true);
          setError(err.response.data.message);
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <AsideCardLayout
        headerContent={
          !checkParamsForIntegration && (
            <CustomIconButton onClick={getContractorDocumentPage}>
              <OpenInNew />
            </CustomIconButton>
          )
        }
        headerTitle="договор"
        rightContent={
          !checkParamsForIntegration && (
            <EditModeButton
              isEdit={isEdit}
              onClose={closeEdit}
              onOpenEdit={startEdit}
              onSubmit={submit}
              loading={isLoading}
              disabledEdit={readOnly || !contract}
            />
          )
        }
        sx={{ height: "fit-content" }}>
        <Box sx={{ mt: "6px" }}>
          {!isEdit && contract === null && <EmptyText noWrap>Добавьте договор</EmptyText>}
          {!isEdit && contract !== null && (
            <Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "100%",
                    sm: "calc(50% - 6px) 50%",
                    md: "100%",
                    lg: "calc(50% - 6px) 50%",
                  },
                  rowGap: "6px",
                  columnGap: "6px",
                }}>
                <Typography>
                  Договор №{contract.number} от {dayjs(contract?.date)?.format("DD.MM.YYYY")}
                </Typography>
                <Box sx={{ display: "flex" }}>
                  {contract.link !== null ? (
                    <Stack direction={"row"} spacing={1} alignItems={"center"} width={"100%"}>
                      <Link
                        color={"secondary"}
                        component={RouterLink}
                        to={contract?.link}
                        target="_blank"
                        sx={{ display: "inline-block" }}
                        noWrap>
                        {contract?.link}
                      </Link>
                      <CopyIconButton copyText={contract?.link} />
                    </Stack>
                  ) : (
                    <EmptyText>ссылка не добавлена</EmptyText>
                  )}
                </Box>
                <Typography>Сумма договора:</Typography>
                {contract.summ ? (
                  <Typography>
                    {contract?.summ ? priceTransormFormat(contract?.summ, true) : <EmptyText />}
                  </Typography>
                ) : (
                  <EmptyText>отсутствует</EmptyText>
                )}
                {attachment && (
                  <>
                    <Typography>
                      Приложение №{attachment?.number} от{" "}
                      {dayjs(attachment?.date)?.format("DD.MM.YYYY")}
                    </Typography>
                    <Box sx={{ display: "flex" }}>
                      {attachment.link !== null ? (
                        <Stack direction={"row"} spacing={1} alignItems={"center"} width={"100%"}>
                          <Link
                            color={"secondary"}
                            component={RouterLink}
                            to={attachment.link}
                            target="_blank"
                            sx={{ display: "inline-block" }}
                            noWrap>
                            {attachment.link}
                          </Link>
                          <CopyIconButton copyText={attachment.link} />
                        </Stack>
                      ) : (
                        <EmptyText>ссылка не добавлена</EmptyText>
                      )}
                    </Box>
                    <Typography>Сумма приложения:</Typography>
                    <Typography>
                      {attachment ? (
                        priceTransormFormat(attachment?.serviceCost, true)
                      ) : (
                        <EmptyText />
                      )}
                    </Typography>
                  </>
                )}
                {additionalAgreement && (
                  <>
                    <Typography>
                      Дополнительное соглашение №{additionalAgreement?.number} от{" "}
                      {dayjs(additionalAgreement?.date)?.format("DD.MM.YYYY")}
                    </Typography>
                    <Box sx={{ display: "flex" }}>
                      <Link
                        color={"secondary"}
                        component={RouterLink}
                        to={additionalAgreement?.link}
                        target="_blank"
                        sx={{ display: "inline-block" }}
                        noWrap>
                        {additionalAgreement?.link}
                      </Link>
                    </Box>
                  </>
                )}
              </Box>
              <Divider sx={{ borderStyle: "dashed", mt: "6px", mb: "6px" }} />
              <ContractCheckbox
                initValue={contract?.isNDS}
                onChange={() => ""}
                text="НДС"
                readOnly
                field="isNDS"
              />
              <Divider sx={{ borderStyle: "dashed", mt: "6px", mb: "6px" }} />
              <Box>
                <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                  <Typography>Трек номер отслеживания:</Typography>
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}>
                    {trackNumber?.toUpperCase() || <EmptyText />}
                  </Typography>
                  {trackNumber && <CopyIconButton copyText={trackNumber || ""} />}
                </Stack>
                <Box>
                  <ContractCheckbox
                    initValue={isOriginalsReceived || false}
                    onChange={() => ""}
                    text="Оригиналы получены"
                    readOnly
                    field="isOriginalsReceived"
                  />
                </Box>
              </Box>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Typography>Комментарий:</Typography>
                {contract?.contractComment ? (
                  <Typography overflow={"hidden"}>{contract?.contractComment}</Typography>
                ) : (
                  <EmptyText>отсутствует</EmptyText>
                )}
              </Stack>
            </Box>
          )}
          {isEdit && (
            <Stack spacing={"6px"}>
              {contract && (
                <Stack direction={"column"} spacing={1}>
                  <Stack spacing={1} direction={"row"} alignItems={"center"}>
                    <Typography sx={{ whiteSpace: "nowrap" }}>
                      Договор №{contract?.number} от {dayjs(contract?.date).format("DD.MM.YYYY")}
                    </Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    maxWidth={"330px"}>
                    <Typography>Ссылка на договор:</Typography>
                    <CreateDocumentField
                      name="contractLink"
                      value={editContractBlock?.contractLink ? editContractBlock?.contractLink : ""}
                      onChange={handleContractInputChange}
                      placeholder="Введите ссылку"
                      disabled={isLoading}
                    />
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    maxWidth={"330px"}>
                    <Typography>Сумма договора:</Typography>
                    <CreateDocumentField
                      name="contractSumm"
                      value={editContractBlock?.contractSumm ? editContractBlock?.contractSumm : ""}
                      onChange={handleContractInputChange}
                      placeholder="Введите сумму"
                      type="number"
                      disabled={isLoading}
                    />
                  </Stack>
                </Stack>
              )}
              <>
                {attachment && (
                  <>
                    <Divider sx={{ borderStyle: "dashed" }} />
                    <Typography fontWeight={"bold"}>Приложение</Typography>
                    <Stack direction={"column"} spacing={1}>
                      <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        <Typography sx={{ whiteSpace: "nowrap" }} maxWidth={"130px"}>
                          Приложение № {attachment?.number} от{" "}
                          {dayjs(attachment?.date).format("DD.MM.YYYY")}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        maxWidth={"330px"}>
                        <Typography maxWidth={"130px"}>Ссылка на приложение:</Typography>
                        <CreateDocumentField
                          name="attachmentLink"
                          value={
                            editContractBlock?.attachmentLink
                              ? editContractBlock?.attachmentLink
                              : ""
                          }
                          onChange={handleContractInputChange}
                          placeholder="Введите ссылку"
                          disabled={isLoading || editContractBlock?.attachmentId === null}
                        />
                      </Stack>
                      <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        <Typography maxWidth={"140px"}>Сумма приложения:</Typography>
                        {attachment?.serviceCost && (
                          <Typography>
                            {priceTransormFormat(attachment?.serviceCost, true)}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  </>
                )}
              </>
              {!editContractBlock?.additionalAgreement && !isAddAdditionalAgreement && (
                <Box>
                  <AddedButton
                    variant="outlined"
                    onClick={() => setAddAdditionalAgreement(true)}
                    size="small"
                    disabled={isLoading}>
                    добавить доп.соглашение
                  </AddedButton>
                </Box>
              )}
              {(editContractBlock?.additionalAgreement || isAddAdditionalAgreement) && (
                <>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography fontWeight={"bold"}>Дополнительное соглашение</Typography>
                    <RemoveItemButton
                      onClick={handleRemoveAdditionalAgreement}
                      disabled={isLoading}
                    />
                  </Stack>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    alignItems={{ xs: "start", sm: "center" }}
                    justifyContent={"space-between"}
                    spacing={{ xs: "6px", sm: 0 }}>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      spacing={"6px"}
                      sx={{
                        width: { xs: "100%", sm: "auto" },
                        justifyContent: { xs: "space-between", sm: "auto" },
                      }}>
                      <Typography sx={{ whiteSpace: "nowrap" }}>
                        Доп. соглашение №
                        {isEdit ? (
                          <Typography component="span" color={"error"}>
                            *
                          </Typography>
                        ) : null}
                      </Typography>
                      <CreateDocumentField
                        name="number"
                        value={editContractBlock?.additionalAgreement?.number}
                        placeholder="Введите номер"
                        disabled={isLoading}
                        onChange={handleAdditionalAgreementChange}
                      />
                    </Stack>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      spacing={1}
                      sx={{ width: { xs: "100%", sm: "auto" } }}>
                      <Typography>
                        от
                        {isEdit ? (
                          <Typography component="span" color={"error"}>
                            *
                          </Typography>
                        ) : null}
                      </Typography>
                      <CreateDocumentDatePicker
                        name="date"
                        value={
                          editContractBlock?.additionalAgreement?.date
                            ? dayjs(editContractBlock?.additionalAgreement?.date)
                            : null
                        }
                        onChange={handleDateChange}
                        placeholder="дд.мм.год"
                      />
                    </Stack>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography>Ссылка на доп. соглашение:</Typography>
                    <CreateDocumentField
                      name="link"
                      value={editContractBlock?.additionalAgreement?.link}
                      placeholder="Введите ссылку"
                      disabled={isLoading}
                      onChange={handleAdditionalAgreementChange}
                    />
                  </Stack>
                </>
              )}
              <Divider sx={{ borderStyle: "dashed" }} />
              <Box>
                <ContractCheckbox
                  onChange={() => ""}
                  initValue={contract?.isNDS || false}
                  disabled={true}
                  text="НДС"
                  readOnly={false}
                  field="isNDS"
                />
              </Box>
              <Divider sx={{ borderStyle: "dashed" }} />
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                maxWidth={"380px"}>
                <Typography>Трек номер отслеживания:</Typography>
                <CreateDocumentField
                  name="trackNumber"
                  value={editContractBlock?.trackNumber}
                  placeholder="Введите номер"
                  disabled={isLoading}
                  onChange={handleContractInputChange}
                  textCase="uppercase"
                />
              </Stack>
              <Box>
                <ContractCheckbox
                  initValue={editContractBlock?.isOriginalsReceived || false}
                  onChange={(checked, field) =>
                    setEditContractBlock(field as keyof IEditContractDataRequest, checked)
                  }
                  disabled={isLoading}
                  text="Оригиналы получены"
                  readOnly={false}
                  field="isOriginalsReceived"
                />
              </Box>
              <Box display={"grid"} gridTemplateColumns={"100px 1fr"} alignItems={"center"}>
                <Typography>Комментарий:</Typography>
                <CreateDocumentField
                  name="contractComment"
                  value={editContractBlock?.contractComment}
                  placeholder="Введите комментарий"
                  disabled={isLoading}
                  onChange={handleContractInputChange}
                  maxWidth="100%"
                />
              </Box>
            </Stack>
          )}
        </Box>
      </AsideCardLayout>
      {isShowError && (
        <NotificationAlerts
          error={{
            text: error ? error : "",
            open: isShowError,
            onClose: () => {
              setShowError(false);
              setError("");
            },
          }}
        />
      )}
    </>
  );
};
